import React, { Component } from 'react';
import signature from '../images/signature.gif';
import complex_map_arrow from '../images/complex_map_arrow.png'
import {Link} from 'react-router-dom'


class Home extends Component {
    render() {
        return (
            <div>
                <div className="row my-4">
                    <div className="col text-center">
                        <Link to="/covid19"><h4>A Message To Our Patients About The Covid-19 Pandemic</h4></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>Hello friends,</p>
                        <p>
                            Due to a change in ownership of the Forest Lane Medical Center, we will be relocating our dental practice in 2007.
                            We may be changing locations, but we won't change our primary philosophy: your dental health is our main priority.
                        </p>
                        <p>
                            I have enjoyed 20 years of dental practice at this Forest Lane location and some of you have been coming here for over
                            40 years. I want to thank you for your loyalty and I look forward to seeing you at our new location.
                        </p>
                        <p>
                            We are excited about the new office. It will be located nearby at the southeast corner of Hillcrest Road and L.B.J. Freeway (I-635).
                            Our phone remains 214-363-5528. We will be in Suite J-108 of the Commerce Plaza Hillcrest complex.
                        </p>
                        <p>
                            On the site, you will find a couple of maps showing the location of the new office at the Hillcrest Road location.
                        </p>
                        <p>
                            <img src={signature} alt="John S Wiggans, DDS, Dallas Texas Family and Cosmectic Dentistry" />
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <img src={complex_map_arrow} alt="John S Wiggans, DDS, Dallas Texas Family and Cosmectic Dentistry" className="img-fluid" />
                    </div>
                </div>
            </div>
        )
    }
}

export default Home