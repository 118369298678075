import React, {Component} from 'react';

class PatientForms extends Component {

    render() {
        return (
            <div>
                <div className="row my-4">
                    <div className="col">
                        <p>Prior to your visit, please download and fill out the appropriate forms.</p>                        
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ul>
                            <li><a href="/PatientForms/New_Patient_Form.pdf" target="_blank">New Patient Form</a>.</li>
                            <li><a href="/PatientForms/Patient_Screening_Form.pdf" target="_blank">Covid-19 Patient Screening Form</a>. Due to the ongoing Covid-19 pandemic, we are requiring ALL patients fill out the Patient Screening Form prior to each appointment</li>
                        </ul>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col">
                        <div style={{height: 50}}></div>                   
                    </div>
                </div>

            </div>
        )
    }
}

export default PatientForms;