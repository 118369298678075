import React from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { Route, Switch } from "react-router-dom";
import Home from './Components/Home';
import  OfficeLocation from './Components/OfficeLocation';
import Covid19 from './Components/Covid19';
import PatientForms from './Components/PatientForms';

function App() {
  return (
    <div className="container">
        <Header />
          <Switch>
            <Route component={Home} path='/' exact />
            <Route component={OfficeLocation} path='/location' />
            <Route component={Covid19} path='/covid19' />
            <Route component={PatientForms} path='/forms' />
          </Switch>
        <Footer />
    </div>
  );
}

export default App;
