import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faFax } from '@fortawesome/free-solid-svg-icons'


class Footer extends Component {
    render() {
        return (
            <div>
                <div className="row mt-4">
                    <div className="col line">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        &copy;{new Date().getFullYear()} John S. Wiggans, DDS
                        <br />
                        <FontAwesomeIcon icon={faPhone} /> 214-363-5528, <FontAwesomeIcon icon={faFax} /> 214-363-5561; Errors? <a href="mailto:johnwiggans@hotmail.com?subject=site error">please report them here</a>
                    </div>
                </div>
            </div>
        )
    };
}

export default Footer;