import React, { Component } from 'react';
import header from '../images/header.gif'
import familycosmetic from '../images/familycosmetic.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope} from '@fortawesome/free-regular-svg-icons'
import { faPhone, faFax } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-router-dom";

class Header extends Component {
    constructor(){
        super();
        this.state = {
            collapsed: true
        };
    }

    doCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    collapseMenu = () => {
        this.setState({
            collapsed: true
        });
    }

    render() {

        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';        

        return (
            <div>   
                <div className="row mt-3">
                    <div className="col text-center">
                        <img src={header} alt="John S Wiggans, DDS, Dallas Texas Family and Cosmectic Dentistry"  className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <img src={familycosmetic} alt="John S Wiggans, DDS, Dallas Texas Family and Cosmectic Dentistry" className="img-fluid" />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col text-center">
                        <ul className="list-inline">
                            <li className="list-inline-item mr-4"><FontAwesomeIcon icon={faPhone} /> 214-363-5528</li>                            
                            <li className="list-inline-item mr-4"><FontAwesomeIcon icon={faFax} /> 214-363-5561</li>
                        </ul>
                    </div>
                </div>           
                <nav className="navbar navbar-expand-lg navbar-light">
                    <button className={classTwo} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                        aria-expanded="false" aria-label="Toggle navigation" onClick={this.doCollapse}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={classOne} id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item"><NavLink onClick={this.collapseMenu} exact activeClassName="active" className="nav-link" to={'/'}>Home</NavLink></li>
                            <li className="nav-item"><NavLink onClick={this.collapseMenu} activeClassName="active" className="nav-link" to={'/location'}>Map/Directions</NavLink></li>
                            <li className="nav-item"><NavLink onClick={this.collapseMenu} activeClassName="active" className="nav-link" to={'/forms'}>Patient Forms</NavLink></li>
                            <li className="nav-item"><NavLink onClick={this.collapseMenu} activeClassName="active" className="nav-link" to={'/covid19'}>Covid-19 Safety Information</NavLink></li>
                            <li className="nav-item"><a onClick={this.collapseMenu} className="nav-link" href="mailto:johnwiggans@hotmail.com"><FontAwesomeIcon icon={faEnvelope} /> Email</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        )
    };
}

export default Header;