import React, {Component} from 'react';


class OfficeLocation extends Component {
    render() {
        return(
            <div>
                <div className="row my-4">
                    <div className="col text-center">
                        <p>Office location:</p>
                        <p>
                            12880 Hillcrest  Road, Suite J-108 
                            <br />Dallas, TX 75230
                        </p>
                        <p>
                        For directions to our office, please use the google map below by clicking on the directions icon. 
                        </p>
                    </div>
                </div>
                <div className="row mt-2 mb-3">
                    <div className="col text-center">
                        <div className="embed-responsive embed-responsive-4by3">
                        <iframe title="map_to" className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.0568659288188!2d-96.78547378431242!3d32.923095780927966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c205bf7da77a1%3A0xb36baa8e90a26a7e!2sWiggans+John+S+DDS!5e0!3m2!1sen!2sus!4v1563662143645!5m2!1sen!2sus" width="600" height="450" frameBorder="0"  allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}


export default OfficeLocation