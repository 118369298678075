import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'



class Covid19 extends Component {

    render() {
        return (
            <div>
                <div className="row my-4">
                    <div className="col text-center">
                        <h4>Our commitment to our patients safety is our top prioirty</h4>
                    </div>
                </div>
                <div className="row px-5">
                    <div className="col">
                        <p>
                            <i><FontAwesomeIcon icon={faExclamationTriangle} color="red" /> Due to the ongoing Covid-19 pandemic, we are requiring ALL patients fill out the Patient Screening Form prior to each appointment. You can
                            download the form <a href="/PatientForms/Patient_Screening_Form.pdf" target="_blank">here</a></i>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>We hope this letter finds you and your families in good health. Our community has been through a lot the past few months,
                        and all of us are looking forward to resuming our normal habits and routines. While
                    many things have changed, one thing has remained the same: our commitment to your safety.</p>
                        <p>Infection control has always been a top priority for our practice and you
                        may have seen this during your visits to the office. Our infection control
                        processes are made so that when you receive care, it’s both safe and
                        comfortable. We want to tell you about the infection control procedures
                        we follow in our practice to keep patients and us safe.
                        Our office follows infection control recommendations made by the American Dental Association (ADA), the U.S. Centers for Disease Controland
                        Prevention (CDC) and the Occupational Safety and Health Administration (OSHA).
                        We follow the activities of these agencies to make sure we are up to date on any
                        new rulings or guidance that may be issued. We do this to make sure that our
                        infection control procedures are current and adhere to each agencies’ recommendations.</p>

                        <p>You may see some changes when it is time for your next appointment. We made these changes to help protect our patients and us. For example:</p>
                        <ul>
                            <li>Our office will communicate with you beforehand to ask some screening questions. You will be asked the same questions again when you are in the office.</li>
                            <li>We have hand sanitizer that we will ask you to use when you enter the office. You will also find some in the reception area and throughout the office for your use, as needed.</li>
                            <li>You may notice our waiting room no longer offers magazines, children's toys and so forth, as those items are difficult to clean and disinfect.</li>
                            <li>Appointments will be managed to allow for social distancing between patients. That might mean that you’re offered fewer options for scheduling your appointment.</li>
                            <li>We will do our best to allow greater time between patients to reduce waiting times for you, as well as to reduce the number of patients in the reception area at any one time.</li>
                        </ul>
                        <p>
                            We look forward to seeing you again and are happy to answer any questions you may have about the steps we take to keep you, and every
                            patient safe in our practice.To make an appointment, please call our office at (214) 363-5528.</p>

                        <p>Thank you for being our patient. We value your trust and loyalty and look forward to welcoming back our patients, neighbors and friends.</p>

                        <p>Sincerely,</p>

                        <p>John Wiggans, D.D.S</p>
                    </div>
                </div>

            </div >
        )
    }
}


export default Covid19;

